import SeriesBackgroundBanner from "./SeriesBackgroundBanner/SeriesBackgroundBanner";
import SeriesParentDescription from "./SeriesParentDescription/SeriesParentDescription";

import { getImageByKey } from "../../../scripts/getImageByKey";

import classes from "./SerieParent.module.css";

const SerieCoverImage = ({ asset, coverImage }) => {
	return (
		<div className={classes.imageContainer}>
			{asset?.serie?.coverImageSmall ||
				(asset?.coverImageSmall && (
					<img
						className={classes.coverImage}
						src={coverImage ? coverImage : getImageByKey("fallbackImage")}
						alt="coverImage"
					/>
				))}
		</div>
	);
};

const SeriesParent = ({ asset }) => {
	let coverImage;
	if (asset?.coverImageSmall || asset?.serie?.coverImageSmall) {
		coverImage = asset.isSerie
			? asset?.serie?.coverImageSmall || asset.coverImageSmall
			: asset.coverImageSmall;
	}
	return (
		<>
			<div className={classes.container}>
				{/* BACKGROUND IMAGE */}
				<SeriesBackgroundBanner asset={asset} />

				<div className={classes.secondaryContainer}>
					<SerieCoverImage asset={asset} coverImage={coverImage} />
					<div className={classes.textContainer}>
						<SeriesParentDescription asset={asset} />
					</div>
				</div>
			</div>
		</>
	);
};

export default SeriesParent;
