import React from "react";

import Colors from "../Colors";
import classes from "./PromoBannerDetail.module.css";
import ContentRatings from "../Assets/VideoDetails/ContentRatings";

import { trancuateText } from "../../lib/trancuateText";
import { convertSecondToMin } from "../../scripts/utils";

const PromoBannerDetail = ({ asset }) => {
	const releaseYear = asset?.releaseYear;
	const title = asset?.name || asset?.title;
	const description = trancuateText(asset?.description, 650);

	return (
		<div className={classes.textContainer}>
			{/* TTILE */}

			<div
				className="font-600"
				style={{
					color: Colors.brand,
					margin: "10px 0",
				}}
			>
				{title}
			</div>

			{/* CATEGORIES */}

			<div className={`${classes.infoContainer} font-300`}>
				<div className={classes.categoriesContainer}>
					{asset?.folders?.map((el) => (
						<div className={classes.categories} key={el?.id || el?.assetId}>
							{el?.name}
						</div>
					))}
				</div>

				<div className={classes.durationContainer}>
					{!isNaN(releaseYear) && releaseYear * 1 > 0 && <div>{releaseYear}</div>}
					<div>{convertSecondToMin(asset?.duration)}</div>
				</div>
			</div>
			{/* CDESCRIPTION */}
			<div
				className={`${classes.description} font-300`}
				dangerouslySetInnerHTML={{ __html: description }}
			></div>
			<ContentRatings size={"35px"} asset={asset} />
		</div>
	);
};

export default PromoBannerDetail;
